import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { onMessage } from 'firebase/messaging';

const config = {
    apiKey: "AIzaSyChmseSTN-hPh0syqFOSIrsvmUt5PVY0Pc",
    authDomain: "miamed-8d477.firebaseapp.com",
    projectId: "miamed-8d477",
    storageBucket: "miamed-8d477.appspot.com",
    messagingSenderId: "1066519068760",
    appId: "1:1066519068760:web:99e35592a86ae983394a2d",
};

firebase.initializeApp(config);
console.log(firebase.messaging())
export const messaging = firebase.messaging();

export const onMessageListener = () =>
    new Promise((resolve) => {
        // Assuming 'messaging' is your Firebase messaging instance
        onMessage(messaging, (payload) => {
            console.log("payload", payload);
            resolve(payload);
        });
    });

